//React

import React, { Component } from 'react';
import { Prompt } from 'react-router';
//Core

import {
  Typography,
  Grid,
  Dialog,
  DialogContent,
  Slide,
  AppBar,
  Toolbar,
  IconButton
} from '@material-ui/core';
//Components

import { UserSelector } from 'components';
import {
  TabPanels,
  AlertDialog
} from './components';
import PerfectScrollbar from 'react-perfect-scrollbar';
//Api

import { apiRequest } from './apiRequest';
//Helpers

import { Constants } from 'stores';
//Icons

import CloseIcon from '@material-ui/icons/Close';
//Style

import style from './style';
import theme from 'theme';
import { withStyles } from '@material-ui/core/styles';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class OnlyGoalDialog extends Component {

  constructor(props) {
    super(props);

    Constants.setcontentLoading(true);

    this.state = {
      users: null,
      value: 0,
      loading: {
        users: true,
        goalTypes: true,
        lazyData: true,
        download: false
      },
      selectedType: null,
      goalTypes: null,
      error: false,
      userSelectable: true,
      lazyData: null,
      alert: {
        isWeighted: false,
        weightSum: null,
        total: null,
        open: false
      }
    }
  }


  //#Lifecycle methods
  componentDidMount() {
    apiRequest.populateUsers(this);
    apiRequest.populateGoalTypes(this);
  }

  componentDidUpdate(prevProps, prevState) {
    const { loading, users, goalTypes, selectedType } = this.state;

    if (prevState.loading != loading) {
      if (loading.goalTypes === false && loading.users === false) {
        if (goalTypes.length > 0 && users.length > 0) {
          if (loading.lazyData === true) {
            apiRequest.populateGoals(this, 1, selectedType.id);
          }
        } else {
          Constants.setcontentLoading(false);
        }

      }
      if (loading.goalTypes === false && loading.users === false && loading.lazyData === false) {
        Constants.setcontentLoading(false);
      }
    }
  }
  //#EndLifecycle methods
  setParentObjectState = (object, props, newValue) => {
    this.setState(prevState => ({
      [object]: {
        ...prevState[object],
        [props]: newValue
      }

    }));
  }

  handleClose = () => {
    this.setState(prevState => ({
      alert: {
        ...prevState.alert,
        open: false
      }
    }));
  }

  handleError = () => {
    this.setState({
      error: true
    });
  }

  setUserSelectable = (value) => {
    this.setState({ userSelectable: value })
  }

  //Handle type change
  handleSelectChange = (event) => {
    const { alert } = this.state;

    if (alert.weightSum === 100 || alert.total === 0 || alert.isWeighted == false) {
      //Change selected state
      const { goalTypes } = this.state;
      const selectedType = goalTypes.find(type => type.id === event.target.value);
      this.setState({
        selectedType
      });
    }
    else {
      this.setState(prevState => ({
        alert: {
          ...prevState.alert,
          open: true
        }
      }));
    }
  }

  //#Api calls

  //#EndApi calls

  handleBlockedNavigation = (nextLocation) => {
    this.setState(prevState => ({
      alert: {
        ...prevState.alert,
        open: true
      }
    }));
    return false
  }

  handleGoalDownload = (typeId, userId) => {
    this.setState(prevState => ({
      loading: {
        ...prevState.loading,
        download: true
      }
    }));
    apiRequest.downloadGoals(this, typeId, userId);
  }

  render() {
    const { loading, users, alert, lazyData, userSelectable, value, goalTypes, error, selectedType } = this.state;
    const { snackbar, onClose, open, readOnly, classes, selected } = this.props;

    return (
      <Dialog
        fullScreen
        TransitionComponent={Transition}
        open={open}
        onClose={onClose}
        aria-labelledby="form-dialog-title"
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <Typography variant="h6" className={classes.title}>
              Célkitűzések
            </Typography>
            <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <PerfectScrollbar>
          <DialogContent>
            {(loading.goalTypes === false && loading.users === false) &&
              <Grid container spacing={2}>
                <Grid item xs={false} sm={false} md={1} />
                <Grid item md={10} xs={12}>
                  {users.length > 0 ?
                    <UserSelector data={users} value={value}>
                      {lazyData !== null &&
                        <TabPanels
                          selected={selected}
                          readOnly={readOnly}
                          setParentObjectState={this.setParentObjectState}
                          lazyData={lazyData}
                          paginationEnabled={userSelectable}
                          setUserSelectable={this.setUserSelectable}
                          onSelectChange={this.handleSelectChange}
                          selectedType={selectedType}
                          onError={this.handleError}
                          goalTypes={goalTypes}
                          snackbar={snackbar}
                          value={value}
                          index={value}
                          handleDownload={this.handleGoalDownload}
                          userId={users[value].id}
                        />
                      }
                    </UserSelector> :
                    <div style={{ textAlign: 'center', padding: theme.spacing(4) }}>
                      <b>Nem tartozik hozzád a beosztott</b>
                    </div>}
                </Grid>
                <Grid item xs={false} sm={false} md={1} />
              </Grid>
            }
            <Prompt
              when={!(alert.weightSum === 100 || alert.total === 0 || alert.isWeighted == false)}
              message={this.handleBlockedNavigation}
            />
            <AlertDialog open={alert.open} onClose={this.handleClose} />
          </DialogContent>
        </PerfectScrollbar>
      </Dialog>
    );
  }
}

export default withStyles(style)(OnlyGoalDialog)
