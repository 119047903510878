import React, { Component } from 'react';
import { Prompt } from 'react-router';
//Core

import {
    Typography,
    Grid,
    Box,
    Icon,
    Tooltip
} from '@material-ui/core';
//Icons

import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
//Components

import {
    UserSelector,
    UserSelectorWithCheck
} from 'components';
import {
    TabPanels,
    AlertDialog
} from './components';
import { apiRequest } from './apiRequest';
import { Constants } from 'stores';
//Style

import style from './style';
import theme from 'theme';
import { withStyles } from '@material-ui/core/styles';

class NextYearGoals extends Component {

    constructor(props) {
        super(props);

        Constants.setcontentLoading(true);

        this.state = {
            users: null,
            value: 0,
            loading: {
                users: true,
                goalTypes: true,
                notAllowedUsers: true,
                lazyData: true
            },
            notAllowedUsers: [],
            selectedUsersForCopy: [],
            selectedProjectsForCopy: [],
            useCopy: false,
            usePreviousCopy: false,
            selectedType: null,
            goalTypes: null,
            error: false,
            userSelectable: true,
            lazyData: null,
            alert: {
                isWeighted: false,
                weightSum: null,
                total: null,
                open: false
            }


        }


    }


    //#Lifecycle methods

    componentDidMount() {
        apiRequest.populateUsers(this);
        apiRequest.populateGoalTypes(this);
    }

    componentDidUpdate(prevProps, prevState) {
        const { loading, useCopy, usePreviousCopy, users, goalTypes, selectedType } = this.state;
        if (useCopy !== prevState.useCopy) {
            if (useCopy === false) {
                this.setState({
                    selectedUsersForCopy: [],
                    selectedProjectsForCopy: [],
                })
            }
        }
        if (usePreviousCopy !== prevState.usePreviousCopy) {
            if (useCopy === false) {
                this.setState({
                    selectedProjectsForCopy: [],
                })
            }
        }
        if (prevState.loading != loading) {
            if (loading.goalTypes === false && loading.users === false) {
                if (goalTypes.length > 0 && users.length > 0) {
                    if (loading.lazyData === true) {
                        apiRequest.populateGoals(this, 1, selectedType.id);
                    }
                } else {
                    Constants.setcontentLoading(false);
                }

            }
            if (loading.goalTypes === false && loading.users === false && loading.lazyData === false) {
                Constants.setcontentLoading(false);
            }
        }
    }
    //#EndLifecycle methods

    setParentState = (props, newValue) => {
        this.setState({
            [props]: newValue
        });
    }
    setParentObjectState = (object, props, newValue) => {
        this.setState(prevState => ({
            [object]: {
                ...prevState[object],
                [props]: newValue
            }

        }));
    }

    handleClose = () => {
        this.setState(prevState => ({
            alert: {
                ...prevState.alert,
                open: false
            }
        }));
    }


    handleSelectUserForCopy = (event, id) => {
        if (event.target.checked) {
            var selectedUsersForCopy = [...this.state.selectedUsersForCopy];
            selectedUsersForCopy.push(id);
            this.setState({ selectedUsersForCopy });
        }
        else {
            const selectedUsersForCopy = this.state.selectedUsersForCopy.filter(s => s !== id);
            this.setState({ selectedUsersForCopy });
        }
    }

    handleSelectProjectForCopy = (event, id) => {
        if (event.target.checked) {
            var selectedProjectsForCopy = [...this.state.selectedProjectsForCopy];
            selectedProjectsForCopy.push(id);
            this.setState({ selectedProjectsForCopy });
            apiRequest.getNotAllowedUsersForCopy(this, selectedProjectsForCopy.length);

        }
        else {
            const selectedProjectsForCopy = this.state.selectedProjectsForCopy.filter(s => s !== id);
            this.setState({ selectedProjectsForCopy });
            apiRequest.getNotAllowedUsersForCopy(this, selectedProjectsForCopy.length);

        }

    }

    handleSelectAllProjectForCopy = (array) => {
        this.setState({ selectedProjectsForCopy: array });
        apiRequest.getNotAllowedUsersForCopy(this, array.length);

    }



    handleChange = (event, newValue) => {
        const { alert } = this.state;
        const classList = event.target.parentElement.classList.value.toUpperCase();
        if (classList.includes('AVATAR')) {
            if (alert.weightSum === 100 || alert.total === 0 || alert.isWeighted == false) {
                this.setState({
                    value: newValue
                })

            }
            else {
                this.setState(prevState => ({
                    alert: {
                        ...prevState.alert,
                        open: true
                    }
                }));
            }
        }

    };

    setValue = (newValue) => {
        this.setState({
            value: newValue
        })
    }

    handleCopy = (value) => {
        this.setState({
            useCopy: value
        });
    }

    handlePreviousCopy = (value) => {
        this.setState({
            usePreviousCopy: value
        });
    }

    handleError = () => {
        this.setState({
            error: true
        });
    }

    handleSaveCopies = () => {
        const { selectedUsersForCopy, selectedProjectsForCopy } = this.state;
        const model = {
            users: selectedUsersForCopy,
            projects: selectedProjectsForCopy
        }
        apiRequest.saveCopies(this, model);
    }

    handleSavePreviousCopies = (userId) => {
        const { selectedProjectsForCopy } = this.state;
        const model = {
            userId,
            projects: selectedProjectsForCopy
        }
        apiRequest.savePreviousCopies(this, model);
    }

    setUserSelectable = (value) => {
        this.setState({ userSelectable: value })
    }

    //Handle type change
    handleSelectChange = (event) => {
        const { alert } = this.state;
        if (alert.weightSum === 100 || alert.total === 0 || alert.isWeighted == false) {
            //Change selected state
            const { goalTypes } = this.state;
            const selectedType = goalTypes.find(type => type.id === event.target.value);
            this.setState({
                selectedType
            });
        }
        else {
            this.setState(prevState => ({
                alert: {
                    ...prevState.alert,
                    open: true
                }
            }));
        }
    }

    //#Api calls

    //#EndApi calls

    handleBlockedNavigation = (nextLocation) => {
        this.setState(prevState => ({
            alert: {
                ...prevState.alert,
                open: true
            }
        }));
        return false
    }

    render() {
        const { loading, users, alert, notAllowedUsers, lazyData, userSelectable, value, goalTypes, error, useCopy, usePreviousCopy, selectedType, selectedUsersForCopy, selectedProjectsForCopy } = this.state;
        const { snackbar, classes, realIsActive } = this.props;
        return ((loading.goalTypes === false && loading.users === false && loading.lazyData === false) && <div className={classes.root}>
            <Grid
                container
                spacing={4}
            >
                <Grid
                    item
                    md={1}
                    xs={12}
                >
                </Grid>
                <Grid
                    item
                    md={10}
                    xs={12}
                >
                    <Box display="flex" alignItems="center" gridGap="0.5rem">
                        <Typography className={classes.title}>Célkitűzések</Typography>
                        {!realIsActive &&
                            <Tooltip title="Az időszak nem aktív, csak az adminisztrátoroknak elérhető">
                                <Icon className={classes.lockIcon}>
                                    <LockOutlinedIcon />
                                </Icon>
                            </Tooltip>
                        }
                    </Box>
                    <Typography className={classes.title}>Újak meghatározása</Typography>
                    {users.length > 0 ?
                        <React.Fragment>
                            {useCopy &&
                                <UserSelectorWithCheck
                                    loading={loading.notAllowedUsers}
                                    selectedUsersForCopy={selectedUsersForCopy}
                                    selectedProjectsForCopy={selectedProjectsForCopy}
                                    notAllowedUsers={notAllowedUsers}
                                    onSelectUserForCopy={this.handleSelectUserForCopy}
                                    data={users}
                                    value={value}
                                >
                                    <TabPanels
                                        setParentObjectState={this.setParentObjectState}
                                        onSaveCopies={this.handleSaveCopies}
                                        onSavePreviousCopies={this.handleSavePreviousCopies}
                                        onSelectAllProjectForCopy={this.handleSelectAllProjectForCopy}
                                        selectedProjectsForCopy={selectedProjectsForCopy}
                                        onSelectProjectForCopy={this.handleSelectProjectForCopy}
                                        selectedUsersForCopy={selectedUsersForCopy}
                                        onSelectChange={this.handleSelectChange}
                                        selectedType={selectedType}
                                        useCopy={useCopy}
                                        usePreviousCopy={usePreviousCopy}
                                        onCopy={this.handleCopy}
                                        onPreviousCopy={this.handlePreviousCopy}
                                        onError={this.handleError}
                                        goalTypes={goalTypes}
                                        snackbar={snackbar}
                                        value={value}
                                        index={value}
                                        userId={users[value].id}
                                        user={users[value]}
                                    />
                                </UserSelectorWithCheck>
                            }
                            {usePreviousCopy &&
                                <UserSelector
                                    disabled={true}
                                    data={users}
                                    value={value}
                                    onChange={this.handleChange}
                                >
                                    <TabPanels
                                        setParentObjectState={this.setParentObjectState}
                                        onSaveCopies={this.handleSave}
                                        onSavePreviousCopies={this.handleSavePreviousCopies}
                                        onSelectAllProjectForCopy={this.handleSelectAllProjectForCopy}
                                        selectedProjectsForCopy={selectedProjectsForCopy}
                                        onSelectProjectForCopy={this.handleSelectProjectForCopy}
                                        onSelectChange={this.handleSelectChange}
                                        selectedType={selectedType}
                                        useCopy={useCopy}
                                        usePreviousCopy={usePreviousCopy}
                                        onCopy={this.handleCopy}
                                        onPreviousCopy={this.handlePreviousCopy}
                                        onError={this.handleError}
                                        goalTypes={goalTypes}
                                        snackbar={snackbar}
                                        value={value}
                                        index={value}
                                        userId={users[value].id}
                                        user={users[value]}
                                    />
                                </UserSelector>
                            }
                            {useCopy === false && usePreviousCopy === false &&
                                <UserSelector
                                    disabled={!userSelectable}
                                    data={users}
                                    value={value}
                                    onChange={this.handleChange}
                                >
                                    {lazyData !== null &&
                                        <TabPanels
                                            setParentObjectState={this.setParentObjectState}
                                            lazyData={lazyData}
                                            paginationEnabled={userSelectable}
                                            setUserSelectable={this.setUserSelectable}
                                            onSelectChange={this.handleSelectChange}
                                            selectedType={selectedType}
                                            useCopy={useCopy}
                                            usePreviousCopy={usePreviousCopy}
                                            onCopy={this.handleCopy}
                                            onPreviousCopy={this.handlePreviousCopy}
                                            onError={this.handleError}
                                            goalTypes={goalTypes}
                                            snackbar={snackbar}
                                            value={value}
                                            index={value}
                                            userId={users[value].id}
                                            user={users[value]}
                                        />
                                    }
                                </UserSelector>
                            }
                        </React.Fragment> :
                        <div style={{ textAlign: 'center', padding: theme.spacing(4) }}>
                            <b>Nem tartozik hozzád beosztott</b>
                        </div>
                    }
                </Grid>
                <Grid
                    item
                    md={1}
                    xs={12}
                >
                </Grid>
            </Grid>
            <Prompt
                when={!(alert.weightSum === 100 || alert.total === 0 || alert.isWeighted == false)}
                message={this.handleBlockedNavigation}
            />
            <AlertDialog open={alert.open} onClose={this.handleClose} />
        </div>);
    }
}

export default withStyles(style)(NextYearGoals)
