import React, { Component } from 'react';
//Core
import {
    Card,
    CardHeader,
    CardContent,
    CardActions,
    Divider,
    Button,
    Typography
} from '@material-ui/core';

import style from './style';




export default function Welcome(props) {
    const { date } = props;
    const classes = style();
    return (<Card>
        <CardContent>
            <Typography className={classes.title}>Köszöntünk a megújult Teljesítményértékelési rendszerben!</Typography>
            <Typography className={classes.typography}>
                A Merkbau kompetencia alapú, 360<sup>o</sup>-os teljesítményértékelést alkalmaz. Ennek megfelelően munkakörökre szabott kompetenciák kerülnek értékelésre. A tavalyihoz hasonlóan idén is 6-os skálát használunk.
                <br /> <br />
                A szoftver felhasználóbarát – kiegészítő információk, segítő megjegyzések minden menüpontban fellelhetők. Amennyiben ezek mellett is segítségre van szükséged, használd a hibabejelentő menüpontot!
                <br /> <br />
                Reméljük, a megújult arculat és rendszer egy gördülékenyebb és időtakarékosabb teljesítményértékelést jelent majd a Te számodra is!
                <br /> <br />
                Merkbau HR
            </Typography>
        </CardContent>
        <Divider />
    </Card>)
}
